.component-shop-filter {
	background: color('global', 'icecream');
	height: 60px;
	position: relative;
	z-index: 100;

	@include mediaquery(tablet-portrait) {
		display: flex;
		height: 30px;
	}

	.categories {
		width: 100%;
		position: relative;
		z-index: 10;

		@include mediaquery(tablet-portrait) {
			width: 250px;
		}
	}

	.category {
		cursor: pointer;
		text-transform: uppercase;
		font-size: 14px;
		text-decoration: none;
		opacity: 1;
		margin: 0 0 20px 0;
		letter-spacing: 2px;
		width: 100%;
		@include transition(color .3s ease-in);
		@include sans-serif-medium;

		&.category-child {
			text-transform: none;
			font-size: 12px;
		}

		&.activeCat {
			color: color('global', 'red');
			padding-left: 10px;
			width: 100%;
			display: block;

			&:after {
				content: '\203A';
				position: absolute;
				right: 10px;
				top: 0;
				transform: rotate(90deg);
			}

		
		}
	}

	.categories-inner {
		background: color('global', 'icecream');
		left: 0;
		max-height: 0px;
		overflow: hidden;
		position: absolute;
		top: 26px;
		transition: max-height .3s ease-in;
		width: 100%;
		padding: 0 10px;

	}

	.orderby {
		display: none;
	}

	.sort {
		z-index: 9;

		@include mediaquery(tablet-portrait) {
			width: 300px;
		}
	}
	
	.openCategories {

		.categories-inner {
			overflow: initial;
			max-height: 1000px;
		}

		.activeCat:after {
			transform: rotate(-90deg);
		}
	}
}