
.arrow {
	cursor: pointer;
	display: block;
	height: 28px;
	position: absolute;
	width: 16px;
	z-index: 100;
	// @include transform-rotate(180deg);
	@include transition(all .2s ease-out);
	//set position in component where it's being used

	&:after, &:before {
		background-color: color('global', 'red');
		content: '';
		content: '';
		display: block;
		height: 3px;
		position: absolute;
		right: -4px;
		width: 23px;
		@include transform-rotate(-45deg);
		// @include transition(background-color .4s ease-out);
		// transition-delay: .4s;
	}

	&:after {
		bottom: 5px;
	}

	&:before {
		top: 5px;
		@include transform-rotate(45deg);
	}


	@include mediaquery(desktop) {
		&:hover {
			&:after, &:before {
				background-color: color('global', 'red');
			}
		}
	}
}

.close {
	cursor: pointer;
	display: block;
	height: 40px;
	position: absolute;
	width: 40px;
	z-index: 100;
	//set position in component where it's being used
	@include transition(background-color .4s ease-out, border .4s ease-out);

	@include mediaquery(tablet-landscape) {
		height: 66px;
		width: 66px;
	}

	&:after, &:before {
		background-color: color('global', 'red');
		content: '';
		display: block;
		height: 2px;
		left: 8px;
		position: absolute;
		top: 18px;
		width: 20px;
		@include transform-rotate(45deg);
		@include transition(background-color .4s ease-out);

		@include mediaquery(tablet-landscape) {
			left: 19px;
			top: 30px;
			width: 24px;
		}
	}

	&:before {
		@include transform-rotate(-45deg);
	}

	@include mediaquery(desktop) {
		&:hover {

			&:before, &:after {
				background-color: color('global', 'red');
			}
		}
	}

	&.close-small {
		width: 20px;
		height: 20px;

		&:after, &:before {
			width: 13px;
			left: 0;
			top: 9px;
		}
	}
}

.sr-only{
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
	position: absolute;
    width: 1px;
}

//Creates a larger target space for the hamburger. Apple's iPhone Human Interface Guidelines recommends a minimum target size of 44 pixels wide 44 pixels tall.
.hamburger-wrapper, .cart-wrapper {
	position: absolute;
	top: -11px;
	right: 0;
	height: 50px;
	width: 50px;
	
	@include mediaquery(tablet-portrait) {
		top: -12px;
		right: auto;
		left: 0;
	}

	@include mediaquery(desktop) {
		top: -12px;
		right: auto;
		left: -15px;
	}
}


	.go-cart {
		position: absolute;
		right: 50px;
		color: color('global', 'red');
		font-size: 23px;
		top: 4px;

		@include mediaquery(tablet-portrait) {
			top: 0px;
			right: 20px;
		}

		@include mediaquery(desktop) {
			top: 0px;
			right: 0;
		}
	}



// 3 line hamburger animates to an X when "navOpen" class is added to the body
.hamburger {
	background-color: transparent;
	cursor: pointer;
	display: block;
	height: 18px;
	border-radius: 0;
	border-top: 2px solid color('global', 'red');
	position: absolute;
	right: 11px;
	top: 16px;
	width: 24px;
	z-index: 100;
	transition: background-color .2s ease-in, border-radius .2s ease-in;



	&:after, &:before {
		background-color: color('global', 'red');
		content: '';
		height: 2px;
		left: 0;
		position: absolute;
		top: 6px;
		width: 100%;
		transition: all .2s ease-in;
	}

	&:after {
	  top: 14px;
	}

	.navOpen & {
		border-top: 0;

		&:after {
			top: 8px;
			transform: rotate(45deg);
		}

		&:before {
			top: 8px;
			transform: rotate(-45deg);
		}

	}
}

