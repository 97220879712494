/**** Promo Styles ****/
.component-promo {
	background: white;
	left: 0;
	position: fixed;
	right: 0;
	height: auto;
	bottom: 0px;
	transition: top .3s ease-in;
	width: 100%;
	z-index: 1000;
	padding: 13px $gap $gap*.5 $gap;

	@include mediaquery(tablet-portrait)  {
		top: -30px;
		bottom: auto;
		position: relative;
	}


	.promo-message {
		max-width: $site-width;
		margin: 0 auto;
		position: relative;

		.close {
			top: -3px;
			right: -20px;
		}
	}

	.hidePromo & {
		display: none;
	}

	.openGuide & {
		z-index: -1;
	}

}
