.page-footer {
	background: color('global', 'red');
	padding: $gap*2 $gap $gap*2;
	text-align: center;

	@include mediaquery(desktop) {
		padding: $gap*2 0 $gap*2;

	}

	.component-instagram {
		padding-bottom: $gap;
	
		#sb_instagram {
			padding-bottom: 0 !important;
		}
	}

	.logo {
		width: 100px;
		margin: 0 auto $gap;
	}

	.headline5 {
		color: white;
		margin-right: $gap*.5;
	}
	
	.social {
		color: white;
		font-size: 12px;
	    line-height: 1.5;
	    letter-spacing: 0.91px;
	    margin-bottom: $gap;
	    margin-top: $gap*.5;
		@include sans-serif;

		@include mediaquery(tablet-portrait) {
			display: block;
		}

		a {
			display: inline-block;
			text-transform: none;
			margin-bottom: 5px;
			position: relative;

			&:after {
				content: '\2022\00a0\2022\00a0\2022';
				color: white;
				margin: 0 9px 0 12px;
			}

			&:nth-child(4) {
				&:after {
					display: none;
				}
			}
		}

		.fab {
			display: inline-block;
			vertical-align: middle;
			font-size: 16px;
			vertical-align: -2px;
		}

		address {
			color: color('global', 'red');
			font-size: 12px;
		    line-height: 1.5;
		    letter-spacing: 0.91px;
		    margin-bottom: 0;
			@include sans-serif;
		}
	}

	.dots {
		display: none;

		@include mediaquery(tablet-portrait) {
			opacity: 0;
			display: inline-block;
			margin: 0 6px 0 3px;
			color: white;
		}
	}

	.menu-items {
		font-size: 14px;
		text-transform: uppercase;
		text-align: left;
		@include sans-serif-medium;

		@include mediaquery(tablet-portrait) {
			text-align: center;
		}

		@include mediaquery(tablet-landscape) {
		}
		
		&.menu-items-small {
			text-transform: none;
			@include sans-serif;

			@include mediaquery(tablet-landscape) {
			}
		}

	}

	.menu-item {
		display: block;
		font-size: inherit;
		color: white;
		text-decoration: none;
		margin: 0 0 $gap 0;
		letter-spacing: 2px;
		font-weight: inherit;

		@include mediaquery(tablet-portrait) {
			display: inline-block;
		}


		&:first-child {
			.dots {
				display: none;
			}
		}

		a {
			opacity: 1;
			@include transition(opacity .4s ease-out, color .4s);
			@include mediaquery(desktop) {
				&:hover {
					opacity: .5;
				}
			}
		}

		
	}


	.mc-field-group {
		margin-bottom: 5px;
	}

	#mc_embed_signup {
		margin-bottom: $gap;

		@include mediaquery(tablet-portrait) {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.headline5 {
			margin-bottom: $gap*.5;

			@include mediaquery(tablet-portrait) {
				margin-bottom: 0;
			}
		}
	}

	#mc_embed_signup_scroll {

		display: flex;
		justify-content: center;
	
	}

	input {
		border: 1px solid white;
		font-size: 12px;
		@include placeholder(white);
		width: 200px;
		margin-right: 10px;
	}

	input[type=submit] {
		width: auto;
		background: white;
		color: color('global', 'red');
		text-align: center;
		border: 0;
		text-transform: uppercase;
		padding: 0 10px;
		margin: 0;
		@include sans-serif-medium;
		opacity: 1;
		@include transition(opacity .4s ease-out, color .4s);

		@include mediaquery(desktop) {
			&:hover {
				opacity: .5;
			}
		}
	}
}