a {
	color: inherit;
	cursor: pointer;
	text-decoration: none;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	letter-spacing: inherit;
	opacity: 1;
	@include transition(opacity .3s ease-out);

	@include mediaquery(desktop) {
		&:hover {
			opacity: .5;
		}
	}
}

em, i {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-style: italic;
}


p, .paragraph_sans_serif {
	font-size: 13px;
	color: color('global', 'gray');
	letter-spacing: 0.91px;
	line-height: 1.9;
	margin-bottom: $gap*.5;
	@include sans-serif-light;

	@include mediaquery(tablet-portrait) {
		font-size: 15px;
	}

	&:last-child {
		margin-bottom: 0;
	}



	a {
		text-decoration: underline;

		@include mediaquery(desktop) {
			&:hover {
				text-decoration: none;
			}
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	span {
		color: inherit;
		font: inherit;
	}
}

small {
	color: inherit;
	display: block;
	font-size: 15px;
	@include sans-serif-medium;
}

span {
	font-family: inherit;
	font-size: inherit;
	color: inherit;
}

strong, b {
	color: inherit;
	font-size: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	font-family: inherit;
	@include sans-serif-medium;
}

h1 {
	font-size: 20px;
	text-align: center;
	margin-bottom: $gap*2;
	text-transform: uppercase;
	color: color('global', 'gray');
	@include sans-serif-medium;
}

h2 {
	text-align: center;
	color: color('global', 'gray');
	font-size: 20px;
	text-transform: uppercase;
	margin-bottom: $gap;
	@include sans-serif-medium;

	a {
		font-size: inherit;
		color: inherit;
		font-family: inherit;
	}
}

.intro-text {
	h2 {
		text-align: left;
	}
}

h3 {
	color: color('global', 'gray');
	font-size: 15px;
	text-transform: uppercase;
	margin-bottom: $gap*.25;
	line-height: 1.5;
	@include sans-serif-medium;
}

address {
	text-align: center;
	display: block;
	color: color('global', 'gray');
	font-size: 15px;
	line-height: 1.5;
	letter-spacing: 0.91px;
	margin-bottom: $gap;
	@include sans-serif-light;
}


time {
	display: block;
	color: color('global', 'red');
	font-size: 15px;
	line-height: 1.5;
	letter-spacing: 0.91px;
	margin-bottom: $gap*.25;
	@include sans-serif;
}

.intro-text {
	max-width: 1000px;
	margin: 0 auto $gap;
	color: color('global', 'gray');
	padding: $gap $gap 0 $gap;

	@include mediaquery(tablet-portrait) {
		padding: 0;
	}

}

.dots {
	font-size: 14px;
	text-align: center;
	display: block;

	.home & {
		margin-bottom: $gap;
	}
}


.link-bar {
	text-align: center;
	margin-bottom: $gap*3;
	width: 100%;

	a {
		color: color('global', 'red');
		text-transform: uppercase;
		font-size: 15px;
		display: inline-block;
		@include sans-serif;

		@include mediaquery(tablet-portrait) {
			font-size: 18px;
		}
	}

	.dots {
		display: inline-block;
		margin: 0 $gap;
		vertical-align: 2px;
		
		&:last-of-type {
			display: none;

			.home & {
				display: block;
			}
		}
	}

}



.headline1 {
	text-transform: uppercase;
	display: inline-block;
	color: white;
	font-size: 52px;
    letter-spacing: 7px;
    text-shadow: 0px 1px 5px rgba(150, 150, 150, 0.88);
	@include nexa-bold;

	@include mediaquery(tablet-portrait) {
		font-size: 52px;
		letter-spacing: 7px;
	}

}

.headline5 {
	color: color('global', 'red');
	font-size: 15px;
	display: inline-block;
	@include sans-serif;
}


.align-center {
	text-align: center;
}

.align-left {
	text-align: left;
}

.page-template-page, .page-template-default {

	.dots {
		margin-bottom: $gap*2;
	}
}

