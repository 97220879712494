.component-shop-info{
	max-width: $site-width*.75;
	margin: 0 auto;

	.wp-post-image {
		margin-bottom: 0;
		margin-top: $gap*3;

		@include mediaquery(tablet-portrait) {
			margin: 0;
		}
	}

	.padding-bottom {
		padding-bottom: $gap*3;
	}

	.shop-info {
		margin-bottom: $gap*1.5;
		padding: 0 $gap;
		text-align: left;

		@include mediaquery(tablet-portrait) {
			padding: 0 $gap 0 0;
		}

		p {
			font-size: 14px;
		}

		&:last-child {
			margin-bottom: 0;
		}


		&.map {
			max-width: 600px;
			margin: 0 auto $gap*3;
			padding-bottom: 100%;
			position: relative;
			height: 0;
			overflow: hidden;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
			}
		}

	}

	.events {
		@include mediaquery(tablet-portrait) {
			display: flex;
			flex-wrap: wrap;

		}

		.event {
			margin-bottom: $gap*2;
			
			@include mediaquery(tablet-portrait) {
				width: calc(50% - 20px);
				margin-right: 40px;

				&:nth-child(2n) {
					margin-right: 0;
				}
				
			}
		}
	}
}
