.component-home-ctas {
	margin: 0 auto $gap*2;
	position: relative;
	@include clearRow;

	@include mediaquery(tablet-portrait) {
		margin: 0 auto $gap*3;
	}

	.content-wrapper {
		@include clearRow;
	}

	.home-cta {
		width: 100%;
		padding-bottom: 100%;
		position: relative;
		overflow: hidden;
		height: 0;

		@include mediaquery(tablet-portrait) {
			width: 50%;
			padding-bottom: 50%;
			float: left;
		}
	}

	.home-cta-images {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;

		img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 0;
			@include transition(opacity 2s cubic-bezier(.79,.24,.83,.67));

			&.activeSlide {
				opacity: 1;
			}
		}
	}

	.effect-wrapper {

		.full-link {
			position: absolute;
			top: 0;
			display: block;
			width: 100%;
			height: 100%;
		}

		.home-cta-links {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
			padding: $gap;
			@include transition(transform 0.35s, color 0.35s);
			@include transform(translate3d(0,100px,0));

			a {
				text-transform: uppercase;
				font-size: 14px;
				color: white;
				text-decoration: none;	
				margin: 0 $gap;	
			}
		}

		.home-cta-logo {
			

			img {
				display: block;
				width: auto;
				height: 20px;

				@media only screen and (min-width: 375px) {
					height: 30px;
				}

				@include mediaquery(tablet-landscape) {
					height: 40px;
				}
			}
		}

		&.activeEffect {

			.home-cta-links {
				@include transform(translate3d(0,0,0));
			}


			.home-cta-logo {
				@include transform(translate3d(0,-50%,0) translate3d(0,-40px,0));
			}

		}
	}

}