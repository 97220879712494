.component-gdpr {
	background: rgba(255, 255, 255, .9);
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 20px 0;
	z-index: 10000;
	display: none;
	height: auto;

	.gdpr-message {
		p {
			font-size: 12px;
		    display: block;
		    margin-bottom: 20px;
		    line-height: 1.4;
		}

		.cta {
			font-size: 12px;
			text-transform: uppercase;
			@include sans-serif-medium;
			color: white;
			background: color('global', 'red');
			padding: 10px;
			cursor: pointer;
		}
	}

	.kulaGDPR & {
		display: block;
	}
}