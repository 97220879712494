// Form global styles


input, textarea {
	background-color: transparent;
	border: 1px solid black;
	border-radius: 0;
	color: black;
	display: block;
	font-size: 15px;
	line-height: 1;
	padding: 5px;
	text-align: left;
	width: 100%;
	outline: none;
	height: 40px;
	@include placeholder(black);
}


label {
	color: black;
	letter-spacing: 0;
	clear:both;
	display: block;
	text-align: left;
	margin-bottom: 5px;
	font-size: 12px;

	span {
		display: inline-block;
		font: inherit;
	}
}

textarea {
	padding: $gap;
	height: 170px;
	resize: none;
}

