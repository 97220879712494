// Mixins
// ======
@font-face {
  font-family: 'Nexa-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Nexa-Bold.otf'); /* IE9 Compat Modes */
}

@mixin sans-serif-light {
	font-family: "Rubik", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 300;

}

@mixin sans-serif {
	font-family: "Rubik", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
}

@mixin sans-serif-italic {
	font-family: "Rubik", Helvetica, Arial, sans-serif;
	font-style: italic;
	font-weight: 400;
}

@mixin sans-serif-medium {
	font-family: "Rubik", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 500;
}

@mixin sans-serif-medium-italic {
	font-family: "Rubik", Helvetica, Arial, sans-serif;
	font-style: italic;
	font-weight: 500;
}

@mixin sans-serif-bold {
	font-family: "Rubik", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 700;
}

@mixin nexa-bold {
	font-family: "Nexa-Bold", Helvetica, Arial, sans-serif;
}

// Grid and Responsive
// ===================
$gap						: 20px;
$site-width					: 1100px;
$nav-height					: 115px;
$nav-height-scrolled		: 75px;
$nav-height-desktop			: 170px;

$tablet-portrait-breakpoint	: 768px;
$tablet-landscape-breakpoint: 1024px;
$desktop-breakpoint			: 1100px;
$large-desktop-breakpoint	: 1440px;
$nav-breakpoint				: 1150px;

// Colors
// ======
$brand-color-key: 'red' !default;
$brand-colors: (
	'debug': (
		'red': 			#f00
	),
	'global': (
		'black'		:	#000,
		'gray'		:	#414042,
		'white'		:	#faf8f7,
		'pale'		:	rgb(250,248,247),
		'red'		:	rgb(119,0,53),
		'icecream'	:	rgb(245,252,251)
	)
);

// Standard colors
// ===============
$white: #FFFFFF;
$black: #000000;

@function color($key: 'debug', $variant: $brand-color-key) {
	$color: null;
	$color-spectrum: map-get($brand-colors, $key);

	@if $color-spectrum {
			$color: map-get($color-spectrum, $variant);
	}

	@return $color;
}

// Generate background and text color classes based on the color map.

@each $key, $map in $brand-colors {
	@each $variant, $color in $map {
		.bg-#{$key}-#{$variant} {
			background-color: #{$color};
		}

		.text-color-#{$key}-#{$variant} {
			color: #{$color};
		}
	}
}

// Responsive Font Size Map
// ========================
// $font-size-headline1: (
// 	null  : 48px,
// 	768px : 48px,
// 	1024px: 72px,
// 	1025px: 72px
// );

