* {
	// background: rgba(255, 0, 0, .1);
}

// Prevents flashing for fade me in transition 
// (js adds opacity 1 once loaded)
// *:not(.main-header), *:not(.page-footer) {
// 	h1, h2, h3, h4, h5, p {
// 		opacity: 0;

// 		&.dont-fade-me-in {
// 			opacity: initial;
// 		}
// 	}
// }

body {
	background-color: color('global', 'icecream');
	font-size: px-to-rem(18px);
	height: 100%;
	min-height: 100vh;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	@include sans-serif-light;

}

img {
	height: auto;
	width: 100%;
}

main.content {
	width: 100%;
	// padding-top: 76px;
	min-height: 60vh;
	padding-top: 40px;
	
	// @include mediaquery(tablet-portrait) {
	// 	padding-top: 161px;

	// 	.hidePromo & {
	// 		padding-top: 123px;
	// 	}
	// }
}

.padding-top-4x {
	padding-top: $gap*4;
}

mark {
	background-color: transparent;
}

.absolute {
	bottom: 0;
	position: absolute;
	right: 0;
}

.background-gradient {
	background-color: rgba(0, 0, 0, .3);
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

//===
// Clearfix: contain floats
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//	`contenteditable` attribute is included anywhere else in the document.
//	Otherwise it causes space to appear at the top and bottom of elements
//	that receive the `clearfix` class.
// 2. The use of `table` rather than `block` is only necessary if using
//	`:before` to contain the top-margins of child elements.
//
.clearfix:before,
.clearfix:after {
	content: " "; // 1
	display: table; // 2
}

.clearfix:after {
	clear: both;
}

.center {
	margin: 0 auto;
}

.component {
	height: 100%;
	width: 100%;
}

.component-alignment-bottom {
	@include flexbox(flex);
	@include flexbox-align-items(flex-end);
}

.component-alignment-center {
	@include flexbox(flex);
	@include flexbox-align-items(center);
}

.component-alignment-stretch {
	@include flexbox(flex);
	@include flexbox-align-items(stretch);
}

.component-alignment-top {
	@include flexbox(flex);
	@include flexbox-align-items(flex-start);
}

.component-row,
.component-row-inner {
	background-position: center center;
	background-repeat: no-repeat;
	height: auto;
	position: relative;
	width: 100%;
	height: 100%;
}

.component-row {
	@include clearRow;
	z-index: 2;

	@media screen and (max-width: 767px) {
		// reverse media query to avoid extra css
		.row-reverse-mobile {
			@include flexbox-direction(column-reverse);
			@include flexbox(flex);
			&:after {
				display: none;
			}
		}
	}

	&.zindexTop {
		z-index: 100000;
	}

}

.component-row-standard {
	margin: 0 auto;
	max-width: $site-width;
	padding: 0;
	position: relative;
	width: 100%;

	.tight-padding-on-mobile & {
		padding: 0 10px;

		@include mediaquery(tablet-landscape) {
			padding: 0 $gap*1.25;
		}
	}

	@include mediaquery(desktop) {
		padding: 0 $gap*2;
	}

	@include mediaquery(xl) {
		padding: 0;
	}

	// iPhone X
	@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
		padding: 0 40px;
	}
}

.component-theme-black {
	background-color: color('global', 'black');
}

.component-theme-grey {
	background-color: color('global', 'grey');
}

.component-theme-white, .component-theme-default {
	background-color: $white;
}

.component-theme-magenta {
	background-color: color('global', 'magenta');
}

.component-theme-purple {
	background: #361C3D;
	background-image: linear-gradient(to right, color('global', 'gradient-start') 0%, color('global', 'gradient-end') 100%);

	.background-gradient {
		background-color: transparent;
	}
}

.component-theme-default {
	background-color: transparent;
}

.content-wrapper {
	position: relative;
}

.desktop-hidden {
	display: inherit !important;

	@include mediaquery(desktop) {
		display: none !important;
	}
}

.foreground {
	z-index: 1000;
}

//===
// Hide from both screenreaders and browsers: h5bp.com/u
//
.fade-me-in {
	opacity: 0;
	@include transition(opacity 1s);

	&.faded-in {
		opacity: 1;
		@include transition(opacity 1s);
	}
}

.fill-image {
	left: 0;
	object-fit: cover;
	position: absolute;
	right: 0;
	top: 0;
}

.gallery img {
	// Overrides wysiwyg gallery border. cannot remove all the
	// styles programmatically without causing layout issues.
	border: 0 !important;
}

.hideOnMobile {
	display: none;

	@include mediaquery(tablet-portrait) {
		display: initial;
	}
}

.hideOnTabletLandscape {
	display: block;

	@include mediaquery(tablet-landscape) {
		display: none;
	}
}

.hideOnTabletPortrait {
	display: block;

	@include mediaquery(tablet-portrait) {
		display: none;
	}
}

.hidden {
	display: none !important;
	visibility: hidden;
}


//===
// Hide visually and from screenreaders, but maintain layout
//
.invisible {
	visibility: hidden;
}

//===
// Image replacement, for logos and such
//
.ir {
	background-color: transparent;
	border: 0;
	overflow: hidden;
	// IE 6/7 fallback
	//text-indent: -9999px;

	&:before {
		content: '';
		display: block;
		height: 150%;
		width: 0;
	}
}


.pure-g {
	letter-spacing: inherit;
	@include flexbox-align-content(inherit);
}

.responsive-image {
	display: block;
	height: auto;
	left: -50%;
	margin: 0 auto;
	min-height: 100%;
	min-width: 100%;
	position: absolute;
	right: -50%;
	top: 0;
	width: auto;
	z-index: 1;
}

// set padding-bottom in individual style sheet as appropriate
.responsive-wrapper {
	height: 0;
	overflow: hidden;
	position: relative;
}



.svg-sprite {
	height: 0;
	overflow: hidden;
}



.video-popup {
	background-color: rgba(color('global', 'black'), .8);
	display: none;
	height: 100%;
	left: 0;
	padding: $gap * 3 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 10000;

	@include mediaquery(tablet-landscape) {
		padding: $gap * 5 20%;
	}

	@include mediaquery-max(desktop) {
		padding: $gap * 6 0;
	}

	&.playVideo {
		display: block;

		.close {
			display: block;
			right: $gap;
			top: $gap;
			z-index: 10;

			@include mediaquery(desktop) {
				right: 19%;
				top: 70px;
			}

			&:before, &:after {
				background-color: $white;
			}

			@include mediaquery-max(desktop) {
				top: $gap * 5;
				left: 5%;
				right: initial;
			}
		}
	}
}

.video-wrapper,
.embed-youtube {
	display: block;
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;

	iframe, video {
		bottom: 0;
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
	}
}

.wp-post-image {
	margin-bottom: $gap*2;
}

.post-img {
	max-width: 100%;
	width: auto;

	&.aligncenter {
		display: block;
		margin: 0 auto;
	}
}


.effect-wrapper {
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: -webkit-linear-gradient(top, rgba(72,76,97,0) 0%, rgba(72,76,97,0.8) 75%);
		background: linear-gradient(to bottom, rgba(72,76,97,0) 0%, rgba(72,76,97,0.8) 75%);
		content: '';
		opacity: 0;
		@include transform(translate3d(0,50%,0));
	}

	.effect-wrapper-logo {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		@include flexbox(flex);
		@include flexbox-justify-content(center);
		@include transform(translate3d(0,-50%,0));
		@include transition(transform 0.35s, color 0.35s, opacity .35s);
	}

	&.activeEffect {

		&:before {
			opacity: 1;
			@include transform(translate3d(0,0,0));
		}

	}
}