.component-gallery {
	margin-bottom: $gap*5;

	.gallery-items {
		position: relative;
		@include clearRow;
	}

	.gallery-item {
		cursor: pointer;
		position: relative;

		@include mediaquery(tablet-portrait) {
			width: 50%;
			float: left;
			padding-bottom: 50%;
			overflow: hidden;
			height: 0;
		}

		&.full-width {
			width: 100%;
			float: none;
			clear: both;
		}

		img {
			display:block;

		}
	}

	a.button {
		display: none;
	}

	.product-link {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.effect-wrapper-logo {
		opacity: 0;
		color: $white;
		font-size: 30px;
		text-shadow: 0px 0px 4px rgba(5, 5, 5, 0.72);
		padding: 0 10px;
		visibility: hidden;
		@include nexa-bold;
	}

	.activeEffect {
		.effect-wrapper-logo {
			opacity: 1;
			visibility: visible;
			@include transform(translate3d(0,-50%,0) translate3d(0,-40px,0));
		}
	}

	.gallery-slideshow {
		background-color: rgba(255, 255, 255, .8);
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		height: 100vh;
		z-index: 100;
		display: none;
		@include flexbox-align-items(center);
		@include flexbox-justify-content(center);

		.gallery-inner {
			position: relative;
			max-width: 1300px;
			width: 100%;
		}

		.close {
			top: -60px;
			right: 10px;
		}

		.arrow {
			bottom: -50px;

			@include mediaquery(tablet-landscape) {
				bottom: auto;
				top: 47%;
			}

			&.arrow-prev {
				left: 20px;
				@include transform(rotate(180deg));
			}

			&.arrow-next {
				right: 20px;
			}
		}

		.gallery-items {
			position: relative;
			height: 0;
			padding-bottom: 100%;
			overflow: hidden;
			width: 90%;
			margin: 0 auto;
			display: none;

			@include mediaquery(tablet-landscape) {
				padding-bottom: 50%;
			}

			&.activeGallery {
				display: block;
			}

			.slide-item {
				bottom: 0;
				height: 100%;
				left: 0;
				opacity: 0;
				position: absolute;
				right: 0;
				text-align: center;
				top: 0;
				width: auto;
				overflow: hidden;
				@include transition(opacity .4s ease-in);
				@include flexbox(flex);
				@include flexbox-align-items(center);

				img {
					height: auto;
					margin: 0 auto;
					max-width: 100%;
					width: 100%;

					@include mediaquery(tablet-landscape) {
						height: 95%;
						width: auto;
					}
				}

				.slide-item-inner {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					@include transition(left 1s ease-in);

				}

				&.activeSlide {
					z-index: 1;
					opacity: 1;

					.slide-item-inner {
						left: 0;
					}
				}
			}

			.caption {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				color: color('global', 'gray');
				font-size: 14px;
			}
		}
	}

	&.galleryOpened {
		.gallery-slideshow {
			@include flexbox(flex);
		}
	}

	.upsells & {
		margin-bottom: 0;
		
		h2 {
			font-size: 14px;

		}

		.gallery-items {
			display: flex;
			flex-wrap: wrap;

			@include mediaquery(tablet-portrait) {
				justify-content: center;
			}

			&:after {
				display: none;
			}

		}
		
		.gallery-item {
			height: 0;
    		overflow: hidden;
			width: 50%;
			padding-bottom: 50%;

			@include mediaquery(tablet-portrait) {
				width: 25%;
				padding-bottom: 25%;

			}
		}

		.activeEffect {
			.effect-wrapper-logo {
				transform: translate3d(0, 50%, 0) translate3d(0, -40px, 0)
			}
		}
	}

	&.component-gallery-shop {
		.gallery-items {
			// @include mediaquery(tablet-portrait) {
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;
			// }
		}

		.gallery-item {
			float: none;
			width: 50%;
			padding-bottom: 50%;
			overflow: hidden;
			height: 0;

			@include mediaquery(tablet-landscape) {
				width: calc(100%/3);
				padding-bottom: calc(100%/3);
			}
		}

		.effect-wrapper-logo {
			font-size: 15px;

			@include mediaquery(tablet-portrait) {
				font-size: 20px;
			}
		}

		.activeEffect {
			.effect-wrapper-logo {
				transform: translate3d(0, 25%, 0) translate3d(0, -40px, 0);
			}
		}
	}
}