.component-grid {
	margin-bottom: $gap*5;
	
	.grid-positioning {

		height: 100%;
		@include flexbox(flex);
		@include flexbox-align-items(center);
		@include flexbox-wrap(wrap);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;

		&.grid-right {
			@include flexbox-direction(row-reverse);
		}
	}


	.grid-item {
		// background-color: color('global', 'white');
		position: relative;
		height: 0;
		padding-bottom: 200%;
		overflow: hidden;

		@include mediaquery(tablet-portrait) {
			padding-bottom: 50%;
			@include flexbox(flex);
			@include flexbox-align-items(center);
			@include flexbox-wrap(wrap);
		}
	}

	.grid-image {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		overflow: hidden;

		@include mediaquery(tablet-portrait) {
			width: 50%;
			padding-bottom: 50%;
		}

		img {
			position: absolute;
			top: 0;
			left: -50%;
			right: -50%;
			display: block;
			margin: 0 auto;
			height: 100%;
			width: auto;
		}

		&.openSlides {
			cursor: pointer;
		}
	}

	.grid-text {
		background-color: #f2f1f0;
		position: relative;
		width: 100%;
		height: 50%;
		overflow: hidden;
		text-align: center;
		@include flexbox(flex);
		@include flexbox-align-items(center);
		@include flexbox-justify-content(center);


		@include mediaquery(tablet-portrait) {
			width: 50%;
			height: 100%;
		}

		.grid-text-positioning {
			padding: $gap;

			@include mediaquery(tablet-landscape) {
				padding: $gap*2;
			}
		}

		p {
			text-align: center;
			color: color('global', 'gray');
			width: 70%;
			margin: 0 auto $gap*3;
			font-size: 14px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			color: color('global', 'red');
			text-transform: uppercase;
			font-size: 13px;
			display: inline-block;
			@include sans-serif-medium;
		}

		h2 {
			text-align: center;
			color: color('global', 'gray');
			font-size: 20px;
			text-transform: uppercase;
			margin-bottom: $gap;
			@include sans-serif-medium;

			a {
				font-size: inherit;
				color: inherit;
				font-family: inherit;
			}
		}

	}

	&.galleryOpened {
		.gallery-slideshow {
			@include flexbox(flex);
		}
	}
}