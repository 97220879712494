@import 'vendor';

// // General variables and setup
@import 'vars';
@import 'reset';
@import 'mixins';
@import 'grid';

// // Global elements
@import 'global';
@import 'typography';
@import 'iconography';
@import 'footer';
@import 'nav';
@import 'forms';

// Components
@import 'components/home-ctas';
@import 'components/gallery';
@import 'components/grid';
@import 'components/shop-filter';
@import 'components/contact';
@import 'components/promo';
@import 'components/gdpr';
@import 'components/size-guide';

// Custom components
@import 'woocommerce';
