.component-size-guide {
	.posted_in {
		display: block;
		cursor: pointer;
	}

	.size-guide-wrapper {
		background-color: rgba(255, 255, 255, .8);
		bottom: 0;
		height: 100vh;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 10000;
		padding: 20px;
		display: none;
		align-items: center;
		justify-content: center;

		.size-guide {
			width: 100%;
			position: relative;

			.close {
				right: -44px;
				top: -44px;
				left: auto;
			}

			@include mediaquery(tablet-portrait) {
				max-width: 600px;
			}
		}

		.openGuide & {
			display: flex;
		}
	}
}

