.main-header {
	padding: $gap 0;
	// position: fixed;
	height: 76px;
	
	background: color('global', 'icecream');
	z-index: 1000;
	left: 0;
	top: 0;
	right: 0;
	transition: top .3s ease-in;

	@include mediaquery(tablet-portrait) {
		height: auto;
		padding: $gap*1.5 0 $gap;

		.logo-plain & {
			height: 111px;
		}
	}

	.header-inner {
		max-width: $site-width;
		position: relative;
		margin: 0 auto;
		height: 100%;

		@include mediaquery(tablet-portrait) {
			padding: 0 $gap;
		}


	}

	.openGuide & {
		z-index: -1;
	}

	// .hideNav & {
	// 	top: -104px;

	// 	@include mediaquery(tablet-portrait) {
	// 		top: -235px;
	// 	}
	// }

	.cart-count {
		font-size: 12px;
	    color: white;
	    font-weight: bold;
	    position: absolute;
	    top: 10px;
	    left: 0;
	    width: 20px;
	    text-align: center;
	    display: block;
	}


	.logo {
		width: 150px;
		opacity: 1;
		position: absolute;
		left: 20px;
		margin: 0 auto;
		top: 0;
		text-align: center;
		z-index: 1;
		@include transition(opacity .4s ease-out);

		@include mediaquery(tablet-portrait) {
			height: auto;
			width: 298px;
			left: auto;
			position: relative;
		}

		@include mediaquery(desktop) {
			&:hover {
				opacity: .5;
			}
		}

		a {
			display: block;
			width: 100%;
			height: 100%;
		}

		img {
		}
	}

	.account-link {
		position: absolute;
		right: 81px;
		top: 10px;

		@include mediaquery(tablet-portrait) {
			right: 60px;
			top: 10px;
		}

		@include mediaquery(desktop) {
			right: 40px;
			top: 10px;
		}
	}
}

.main-nav {
	position: fixed;
	right: -100%;
	// right: 0;
	width: 100%;
	top: 76px;
	height: 100vh;
	z-index: 10;
	background: color('global', 'icecream');
	transition: right .3s ease-in;
	z-index: 1001;

	@include mediaquery(tablet-portrait) {
		width: 50%;
		right: auto;
		left: -50%;
		top: 183px;
		transition: left .3s ease-in;
	}

	@include mediaquery(tablet-landscape) {
		width: 300px;
		left: -30%;
	}

	.main-nav-scroll {
		overflow: scroll;
		height: 100%;

		@include mediaquery(tablet-portrait) {
			overflow: initial;
		}

	}

	.navOpen & {
		left: 0;
	}

	.menu-items {
		padding: 20px 20px 50px;

		@include mediaquery(tablet-portrait) {
			padding: 0px 50px 50px;
		}
	}

	.menu-item {
		text-transform: uppercase;
		color: color('global', 'red');
		font-size: 14px;
		text-decoration: none;
		margin: $gap 0 0 0;
		letter-spacing: 2px;
		width: 100%;
		@include sans-serif-medium;

		> .sub-menu {
			max-height: 0;
			overflow: hidden;

			.menu-item {
				text-transform: none;
				font-size: 12px;
			}
		}

		&.menu-item-has-children {
			position: relative;

			> a {
				display: block;
			}

			&:after {
				content: "\f107";
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				right: 0;
				top: -1px;
				position: absolute;
			}

			&.subNavOpen {
				&:after {
					transform: rotate(-180deg);
				}
			}
		}

		&.subNavOpen {
			.sub-menu {
				max-height: 500px;
			}
		}

		a {
			opacity: 1;
			@include transition(opacity .4s ease-out, color .4s);
			@include mediaquery(desktop) {
				&:hover {
					opacity: .5;
				}
			}

		}


		.dots {
			display: none;
		}
	}
}

.back-to-top {
	background-color: rgba(255, 255, 255, .7);
	position: fixed;
	bottom: 10px;
	right: 10px;
	width: 40px;
	height: 40px;
	text-align: center;
	color: color('global', 'red');
	font-size: 40px;
	z-index: 10;
	border-radius: 50%;
	opacity: 0;
	transition: opacity .3s ease-in;

	.fas {
		opacity: .7;
	}

	.hideNav & {
		opacity: 1;
	}
}