.woocommerce {
	padding: 0 $gap;

	@include mediaquery(tablet-landscape) {
		padding: 0;
	}
}

.woocommerce .woocommerce-ordering, .woocommerce-page .woocommerce-ordering {
	float: none;
}

.woocommerce div.product form.cart .variations label {
	font-weight: normal;
}

.woocommerce .quantity .qty {
	color: black;
	height: 31px;
}

.woocommerce span.onsale {
	background: color('global', 'red');
	font-size: 12px;
	height: 50px;
	left: 10px;
	min-height: 0;
	min-width: 0;
	padding: 6px 0 0 0;
	top: 10px;
	width: 50px;
	z-index: 9;
	@include sans-serif-medium;

	@include mediaquery(tablet-landscape) {
	}

	&.soldout {
		background-color: color('global', 'icecream');
		color: black;
	}
}

#add_payment_method table.cart input, .woocommerce-cart table.cart input, .woocommerce-checkout table.cart input {
	color: black;
}

.back {
	display: block;
    margin-bottom: 10px;
    color: color('global', 'red');
    font-size: 12px;
    text-transform: uppercase;
}

.shop_table {
	input[type="radio"], input[type="checkbox"] {
		display: inline-block;
	    margin-right: 5px;
	    width: auto;
	    height: auto;
	    vertical-align: inherit;
	}
}

.woocommerce-breadcrumb {
	display: none;
}
.woocommerce-info {
	border: 0;
	padding: 10px;
	margin: 0 0 $gap 0;
	font-size: 14px;

	&:before {
		display: none;
	}

	.archive & {
		text-align: center;
	}
}

.summary {
	padding: 0  $gap;
}

.product-info {
	margin-bottom: $gap*2;
	border-bottom: 1px solid color('global', 'gray');
	padding-bottom: $gap;
}

.product-info {
	h1 {
		text-align: left;
		text-transform: none;
	}

	.stock {
		display: none;

		&.out-of-stock {
			font-size: 14px !important;
			color: color('global', 'red') !important;
			display: block;
			@include sans-serif-medium;
		}
	}
}

.product-description {
	h2 {
		display: none;
	}
}

.posted_in, .tagged_as {
	display: block;
	margin-bottom: 10px;
	color: color('global', 'gray');
	font-size: 14px;
	@include sans-serif-medium;

	a {
		color: color('global', 'red');
	}
}

.woocommerce div.product p.price, .woocommerce div.product span.price {
	color: color('global', 'red');
	font-size: 14px;
	@include sans-serif-medium;
}

.woocommerce-product-details__short-description {
	margin-bottom: $gap*2;
}

.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button {
	border-radius: 0;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: normal;
	@include sans-serif-medium;
}

.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt {
	background-color: color('global', 'red');
}

.woocommerce #respond input#submit.alt:hover, .woocommerce a.button.alt:hover, .woocommerce button.button.alt:hover, .woocommerce input.button.alt:hover {
	background-color: black;
}

.woocommerce #content div.product div.summary, .woocommerce div.product div.summary, .woocommerce-page #content div.product div.summary, .woocommerce-page div.product div.summary {
	@include mediaquery(tablet-landscape) {
		width: calc(100% - 605px);

	}
}

.woocommerce #content div.product div.images, .woocommerce div.product div.images, .woocommerce-page #content div.product div.images, .woocommerce-page div.product div.images{
	@include mediaquery(tablet-landscape) {
		width: 55%;

	}
}
.woocommerce div.product div.images.woocommerce-product-gallery {
	display: flex;
	margin-bottom: $gap*2;


    @include mediaquery(tablet-landscape) {
	    width: 605px;
	    height: 100%;

	    &.stickTop {
	    	position: fixed;
	    	top: 0;
	    }

	    &.showRelated {
	    	position: relative;
	    }
	}

	.flex-viewport {
		margin-right: 10px;
		width: calc(100% - 65px);
		
	}

	.flex-control-nav {
		width: 50px;

	}
}

.woocommerce div.product div.images .flex-control-thumbs li {
	width: 100%;
	float: none;
	margin-bottom: 10px;
}

.upsells {
	clear: both;
	padding-top: $gap*2;
	opacity: 1;
	transition: opacity .3s ease-in;
	min-height: 100px;

	@include mediaquery(tablet-landscape) {
		min-height: 300px;
		opacity: 0;
	}

	&.showRelated {
		opacity: 1;
	}
}

.woocommerce-form-login {
	max-width: 600px;
	margin: 0 auto !important;
}

.woocommerce-notices-wrapper {
	max-width: $site-width;
	margin: 0 auto;
}

.woocommerce-error, .woocommerce-message {
	border: 0;
}

.woocommerce-error li, .woocommerce-info li, .woocommerce-message li, .woocommerce table.shop_table, .woocommerce table.shop_table td, .woocommerce label, .woocommerce table.shop_table th, .woocommerce-message {
	font-size: 14px;
}

.woocommerce table.shop_table th, .woocommerce table.shop_table tbody th, .woocommerce table.shop_table tfoot td, .woocommerce table.shop_table tfoot th {
	font-weight: normal;
	@include sans-serif-medium;
}

.woocommerce-account .woocommerce-MyAccount-navigation li, .account-link {
	font-size: 14px;
	color: color('global', 'red');
	margin-bottom: 10px;
	text-transform: uppercase;
	@include sans-serif-medium;

}


.woocommerce nav.woocommerce-pagination ul {
	border: 0;

	li {
		border: 0;

		span, a{
			font-size: 18px;
		}

		span.current {
			background: transparent;
			color: color('global', 'red');
		}
	}
}